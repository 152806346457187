<template>
    <div class="card">
      <div class="card-header">
        <h5 class="card-title">Zeitraum</h5>
      </div>
      <div class="card-body">
        <h3>Startzeit: {{ formatDate(start) }}</h3>
        <h3>Endzeit: {{ formatDate(end) }}</h3>
      </div>
    </div>
</template>

<script>
import Helper from "@/utils/helper";

export default {
  name: "TimeCard",
  props: ['start', 'end'],
  methods: {
    formatDate(timestamp) {
      return Helper.formatDate(timestamp);
    }
  }
}
</script>

<style scoped>
</style>
