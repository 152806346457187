<template>
  <div class="card">
    <div class="card-header">
      <h5 class="card-title">Gewinne</h5>
    </div>
    <div class="card-body text-start">
      <div class="row">
        <div class="">
          <p>Diese Plätze erhalten nach erfolgreichem Event folgenden Gewinn:</p>
          <ol>
            <li v-for="win in wins" :key="win">{{ win }}</li>
          </ol>
          <span v-if="winsAll">{{winsAll}}</span>
        </div>
        <div v-if="image" class="col-lg-4 col-12">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">{{ winImageText }}</h5>
            </div>
            <div class="card-body text-start">
              <img :src="image" height="64">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "WinCard",
  props: ['winsAll', 'wins', 'image', 'winImageText'],
}
</script>

<style scoped>
.container {
  display: flex;
  align-items: flex-end;
}

.podiumItem {
  width: 200px;
}

.podiumRank {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  color: #fff;
}

.podiumPlayer {
  text-align: center;
  padding: 0 .5rem;
}

.podiumNumber {
  width: 27px;
  height: 75px;
}

.podium .first {
  min-height: 300px;
  background: rgb(255, 172, 37);
  background: linear-gradient(333deg,
  rgba(255, 172, 37, 1) 0%,
  rgba(254, 207, 51, 1) 13%,
  rgba(254, 224, 51, 1) 53%,
  rgba(255, 172, 37, 1) 100%);
}

.podium .second {
  min-height: 200px;
  background: blue;
}

.podium .third {
  min-height: 100px;
  background: green;
}

</style>
