<template>
  <div class="card">
    <div class="card-header">
      <h5 class="card-title">Regeln/Teilnahmebedingungen</h5>
    </div>
    <div class="card-body text-start">
      <h6>Zu jedem Event gehören auch ein paar Regeln und Rahmenbedingungen:</h6>
      <ul>
        <li>Veröffentlichte Jump'n'Runs müssen den Tag des Events (#{{ tag }}) im Namen haben, um an dem Event teilzunehmen.</li>
        <li>Mehrfachteilnahmen sind möglich.</li>
        <li>Jump'n'Runs dürfen zusammen erbaut werden - allerdings erhält ausschließlich der eingetragene Erbauer die Gewinne.</li>
        <li>Es gelten die allgemeinen timolianischen Regeln.</li>
        <li>Timolia behält sich das Recht vor, Gewinner und Teilnehmer von Events auch nachträglich zu sanktionieren, sollten Beweise für die Nutzung von u.a. Schematika gefunden werden.</li>
      </ul>

      <div v-if="rules.length !== 0">
        <h6>Weiterhin gelten für das aktuelle Event "{{ eventName }}" folgende Zusatzregeln:</h6>
        <ul>
          <li v-for="rule in rules" :key="rule">{{ rule}}</li>
        </ul>
      </div>

      Wir wünschen viel Spaß und Erfolg beim Teilnehmen!
    </div>
  </div>
</template>

<script>
export default {
  name: "RulesCard",
  props: ['eventName', 'tag', 'rules'],
}
</script>

<style scoped>
</style>
