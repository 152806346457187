<template>
  <div class="card">
    <div class="card-header">
      <h5 class="card-title">{{ title }}</h5>
      <p class="m-0">{{ description }}</p>
    </div>
    <div class="card-body">
      <CreatorList :users="users.timolia" title="Teamler" :loaded="loaded"  :playerData="playerData"/>
      <hr v-if="!(users.timolia.length === 0 || users.players.length === 0)">
      <CreatorList :users="users.players" title="Spieler" :loaded="loaded" :playerData="playerData"/>
    </div>
  </div>
</template>

<script>
import CreatorList from "@/components/Events/CreatorList";

export default {
  components: {CreatorList},
  name: "CreatorCard",
  props: ['users', 'title', 'playerData', 'loaded', 'description'],
}
</script>

<style scoped>
</style>
