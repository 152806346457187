<template>
  <div class="container pb-3">
    <div class="row text-center justify-content-center">
      <h1>Event {{ id }} - #{{ event.hashtag }} - {{ event.eventName }}</h1>
      <!--      <h5>{{ event }}</h5>-->
      <div class="col-lg-6 col-12">
        <div class="row">
          <div class="col-12 mt-2" v-if="event.showWinners">
            <Podium :winners="event.winners" :loaded="loadedPlayerData" :playerData="playerData"/>
          </div>
          <div class="col-12 mt-2">
            <CreatorCard title="Event-Planung"
                         description="Diese Spielerinnen und Spieler hatten nicht nur die Idee für das Event, sondern sind auch für die Planung und die Umsetzung des Events zuständig."
                         :users="event.creators" :loaded="loadedPlayerData" :playerData="playerData"/>
          </div>
          <div class="col-12 mt-2">
            <CreatorCard title="Jury"
                         description="Die Jury setzt sich aus ausgewählten Personen zusammen, die die Jump'n'Runs fair und unabhängig bewerten."
                         :users="event.judges" :loaded="loadedPlayerData" :playerData="playerData"/>
          </div>
          <div class="col-12 mt-2">
            <WinCard :wins-all="event.winsAll" :wins="event.wins" :image="event.win.image" :win-image-text="event.win.text"/>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <div class="row">
          <div class="col-12 mt-2">
            <TimeCard :start="event.start" :end="event.end"/>
          </div>
          <div class="col-12 mt-2">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">Beschreibung</h5>
              </div>
              <div class="card-body">
                {{ event.description }}
              </div>
            </div>
          </div>
          <div class="col-12 mt-2">
            <RulesCard :event-name="event.eventName" :tag="event.hashtag" :rules="event.rules"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import jsonEvents from '../assets/events/events.json'
import {env} from "@/environment/env";
import TimeCard from "@/components/Events/TimeCard";
import CreatorCard from "@/components/Events/CreatorCard";
import RulesCard from "@/components/Events/RulesCard";
import WinCard from "@/components/Events/WinCard";
import Podium from "@/components/Events/Podium";

export default {
  name: "Event",
  components: {Podium, WinCard, RulesCard, TimeCard, CreatorCard},
  created() {
    this.event = this.getEvent(this.id);
    const allUsernames = this.getAllUsernames(this.event);
    fetch(env.TIMOLIA_API_URL + '/user/uuids', {
      method: 'POST',
      body: JSON.stringify(allUsernames),
    }).then(response => response.json())
        .then(data => {
          this.loadedPlayerData = true;
          this.playerData = data;
        }).catch((error) => {
      console.log(error);
      this.errorPlayerData = true
    });
  },
  data() {
    return {
      id: this.$route.params.id,
      events: jsonEvents,
      event: {},
      usernames: {},
      playerData: {},
      loadedPlayerData: false,
      errorPlayerData: false,
    }
  },
  methods: {
    getEvent(id) {
      return this.events.filter(event => event.id == id)[0];
    },
    getAllUsernames(event) {
      let users = [];

      // judges
      for (const judgesKey in event.judges) {
        for (const judge in event.judges[judgesKey]) {
          users[users.length] = event.judges[judgesKey][judge];
        }
      }

      // creators
      for (const creatorsKey in event.creators) {
        for (const creator in event.creators[creatorsKey]) {
          users[users.length] = event.creators[creatorsKey][creator];
        }
      }

      // winners
      for (const winner in event.winners) {
        users[users.length] = event.winners[winner].player;
      }

      return users;
    }
  }
}
</script>

<style scoped>

</style>
