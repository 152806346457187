<template>
  <div class="card">
    <div class="card-header">
      <h5 class="card-title">Gewinner</h5>
    </div>
    <div class="card-body text-start">
      <div class="row">
          <div class="container podium">
            <div class="podiumItem">
              <p class="podiumPlayer">
                <UserImage :player-data="playerData" :loaded="loaded" :uuid="winners.p2.player"/>
                <span class="d-grid gap-2">
                  <router-link :to="'/jnr/' + winners.p2.jnrId" class="btn btn-outline-warning">{{ winners.p2.jnrId }}</router-link>
                </span>
              </p>
              <div class="podiumRank second">2</div>
            </div>
            <div class="podiumItem">
              <p class="podiumPlayer">
                <UserImage :player-data="playerData" :loaded="loaded" :uuid="winners.p1.player"/>
                <span class="d-grid gap-2">
                  <router-link :to="'/jnr/' + winners.p1.jnrId" class="btn btn-outline-warning">{{ winners.p1.jnrId }}</router-link>
                </span>
              </p>
              <div class="podiumRank first">1</div>
            </div>
            <div class="podiumItem">
              <p class="podiumPlayer">
                <UserImage :player-data="playerData" :loaded="loaded" :uuid="winners.p3.player"/>
                <span class="d-grid gap-2">
                  <router-link :to="'/jnr/' + winners.p3.jnrId" class="btn btn-outline-warning">{{ winners.p3.jnrId }}</router-link>
                </span>
              </p>
              <div class="podiumRank third">3</div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserImage from "@/components/Events/UserImage";

export default {
  components: {UserImage},
  name: "Podium",
  props: ['winners', 'loaded', 'playerData'],
}
</script>

<style scoped>
.container {
  display: flex;
  align-items: flex-end;
}

.podiumItem {
  width: 33%;
}

.podiumRank {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  color: #fff;
}

.podiumPlayer {
  text-align: center;
  padding: 0 .5rem;
}

.podium .first {
  min-height: 150px;
  background: #ff1300;
}

.podium .second {
  min-height: 100px;
  background: darkorange;
}

.podium .third {
  min-height: 50px;
  background: #ffe400;
}

</style>
