<template>
  <figure>
    <img :src="'https://visage.surgeplay.com/head/64/' + uuid" alt="Creator Image"/>
    <figcaption v-bind:style="{ 'color': '#' + getUserByUUID(uuid)['color'] }">{{ getUserByUUID(uuid)['username'] }}</figcaption>
  </figure>
</template>

<script>
export default {
  name: "UserImage",
  props: ['playerData', 'loaded', 'uuid'],
  methods: {
    getUserByUUID: function (uuid) {
      if (Object.keys(this.playerData).length !== 0) {
        return this.playerData.filter(player => player.uuid === uuid)[0];
      }
      return "";
    }
  }
}
</script>

<style scoped>
</style>
