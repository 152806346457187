<template>
  <div v-if="users.length !== 0">
    <h6 class="text-decoration-underline">{{ title }}</h6>
    <UserImage v-for="uuid in users" v-bind:key="uuid" :player-data="playerData" :loaded="loaded" :uuid="uuid"/>
  </div>
</template>

<script>
import UserImage from "@/components/Events/UserImage";

export default {
  name: "CreatorList",
  components: {UserImage},
  props: ['users', 'title', 'playerData', 'loaded'],
}
</script>

<style scoped>
figure {
  width: 20%;
  text-align: center;
  display: inline-block;
}
</style>
